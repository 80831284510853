<template>
  <MyAccountCheckAuthLoggedIn v-if="!isMobileOrTablet">
    <MyAccountProfileEdit />
  </MyAccountCheckAuthLoggedIn>
</template>

<script setup lang="ts">
const { url } = useDomainHost()
const { isMobileOrTablet } = useDevice()
const { t } = useI18n()
useHead({
  title: t('profile'),
  link: [useSeoCanonical(url + '/my-account')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('profile'), url + '/my-account')
    ])
  ]
})
</script>
